.transfer-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.transfer-success-card {
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px; 
}

.review-details {
  padding: 20px;
  text-align: center;
}

.review-details h5 {
  color: #27ca9f;
}

@media (max-width: 768px) {
  .transfer-success-card {
      width: 90%;
  }
}
