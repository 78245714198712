.help-me-container {
    max-width: 600px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

h2 {
    color: #27ca9f;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

label {
    font-weight: bold;
    margin-bottom: 8px;
}

textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submitHelp {
    background-color: #27ca9f;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 2%;
}

button:hover {
    background-color: #1e995b;
}

@media (max-width: 768px) {
    .help-me-container {
        padding: 15px;
        width: 300px;
    }

    textarea {
        margin-bottom: 15px;
    }

    .submitHelp {
        margin-top: 10px;
    }
}
