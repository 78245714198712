/* Default styles */
.otp-card {
    max-width: 30%; 
    margin: auto; 
}

.otp-heading {
    text-align: center;
    margin-bottom: 20px;
}

.otp-input-group {
    display: flex;
    justify-content: space-between;
}

.otp-input {
    width: 40px;
    text-align: center;
    margin: 0 5px;
    padding: 8px;
    font-size: 16px;
}

.buttonSubmit {
    width: 100%; 
    margin-top: 15px;
    background-color: #27ca9f;
}

.buttonSubmit:disabled {
    opacity: 0.5; 
    cursor: not-allowed; 
}

.text-underline {
    text-decoration: underline;
    color: #27ca9f; 
    cursor: pointer;
}

.otp-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    background-image: url('../images/bg-2.jpg');
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    font-family: 'Catamaran';
    position: relative;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8); 
    z-index: 1000;
    display: none; 
}

.loading {
    overflow: hidden;
}

.loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 8px solid #f3f3f3;
    border-top: 8px solid #27ca9f;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
    .otp-card {
        max-width: 80%;
    }
    
    .otp-input {
        width: 30px;
        padding: 5px;
        font-size: 14px;
    }
    
    .buttonSubmit,
    .text-underline {
        width: 80%;
    }
}
