.onboarding-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
   
}

.ononboardingLogo{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}


.spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

.onboarding-card {
    width: 80%;
    max-width: 600px;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10%;
}

.heading {
    margin-top: 10px;
    margin-bottom: 20px;
    color: black;
  
}

.buttonSubmit {
    background-color: #27ca9f;
    color: #fff;
    margin-top: 10px;
}


.dropdown{
    width: 225px;
    height: 40px; 
}

.onboarding-image {
    width: 50%;
    height: 100%;
    background-image: url('../images/bg-8.jpg');
    background-size: cover; 
    background-position: center; 
  }
