/* forgotPassword.css */

.forgot-password-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../images/bg-3.jpg');
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat;
    font-family: 'Catamaran';
}

.forgot-password-card {
    width: 90%; 
    max-width: 450px;
    margin: auto;
}

.forgot-password-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem; 
}

.form-group {
    margin-bottom: 20px;
}

.forgot-password-text{
    text-align: center;
}

.buttonSubmit {
    width: 100%;
    margin-top: 15px;
    background-color: #27ca9f;
}

.buttonSubmit :focus{
    background-color: black;
}

.text-underline {
    text-decoration: underline;
    color: #27ca9f;
    cursor: pointer;
}

.inputField{
    width: '100%';
}

@media (max-width: 768px) {
    .forgot-password-card {
        width: 80%; 
    }
}

@media (max-width: 576px) {
    .forgot-password-card {
        width: 90%; 
    }
}