.notFoundContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .container {
    width: 450px;
    text-align: center;
    padding-top: 5%;
    padding-bottom: 5%;
  }

  .card{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    padding: 20px;
  }
  
  .notFoundHeader {
    color: #555;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  
  .notFoundParagraph {
    color: #555;
  }
  