.recent-transactions {
    margin-top: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #27ca9f;
    color: #fff;
}

tr:hover {
    background-color: rgba(39, 202, 159, 0.1);
}

.transaction-icon {
    margin-right: 10px;
    color: #27ca9f;
}

.noTransactions{
    font-size: 20px;
    margin-left: 15%;
}



@media (max-width: 768px) {
    .financial-overview {
        flex-direction: column; 
        align-items: center; 
    }

    .overview-card {
        width: 100%; 
        margin-left: 0; 
        margin-bottom: 2%;
    }

    .noTransactions{
        font-size: 20px;
        text-align: center;
    }
}
