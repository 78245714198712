.money-transfer-container {
    display: flex;
    justify-content: center;
}

.money-transfer-card {
    width: 80%;
    max-width: 600px;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.formTransferInput {
    width: 300px;
}

.errorTransfer {
    color: red;
    font-size: 14px;
    margin-top: 2%;
    font-weight: bold;
}

.heading {
    margin-top: 10px;
    margin-bottom: 20px;
    color: black;
    text-align: center;
}

.transferButtonSubmit {
    background-color: #27ca9f;
    color: #fff;
    margin-bottom: 15px;
    width: 300px; 
}

.suggestion-card {
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #333;
}

.suggestion-card:hover {
    background-color: #f5f5f5;
}

.review-confirm-container {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
}

.review-details {
    margin-top: 15px;
}

.review-details p {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .money-transfer-card {
        width: 100%; 
    }

    .transferButtonSubmit {
        max-width: 100%;
    }
}
