.financial-graph {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-left: 5%;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  