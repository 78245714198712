.financial-overview {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }
  
  .overview-card {
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    width: 300px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
    margin-left: 3%;   
  
    &:hover {
      transform: scale(1.05);
    }
  }
  
  .card-icon {
    font-size: 36px;
    margin-bottom: 10px;
    color: #27ca9f;
  }
  
  .card-details h3 {
    font-size: 16px;
    margin: 0;
  }
  
  .card-details p {
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0;
  }

  @media (max-width: 768px) {
    .financial-overview {
        flex-direction: column; 
        align-items: center; 
    }

    .overview-card {
        width: 100%; 
        margin-left: 0; 
        margin-bottom: 2%;
    }
}
  