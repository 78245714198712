
.sidebar {
    background-color: #27ca9f;
    color: #fff;
    padding: 20px;
    width: 200px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    transition: width 0.3s ease; 
  }
  
  .sidebar.collapsed {
    width: 60px; 
  }
  
  .toggle-btn {
    background: none;
    border: none;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }
  
  .sidebar-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
  }
  
  .icon {
    margin-right: 10px;
  }

  .space{
    margin-top: 100px;
  }


  .link{
    text-decoration: none;
    color: #fff;
  }

  .sidebar-item.active {
    text-decoration: underline;
    font-weight: 600;
  }
  


  @media (max-width: 768px) {
    .sidebar.collapsed {
      display: none;
    }
}
  