.update-user-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    margin-top: 5%;
    font-family: 'Catamaran';
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.update-user-card {
    width: 100%;
    max-width: 600px;
    margin: 10px;
}

.update-user-heading {
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
}

.buttonSubmit {
    width: 100%;
    margin-top: 15px;
}

.pie-chart,
.data-display {
    margin-bottom: 20px;
}

.edit-button {
    width: 100%;
    margin-top: 15px;
    background-color: #27ca9f;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
}

.edit-button:hover {
    background-color: #218c7b;
}

.data-field {
    margin-bottom: 10px;
}

.datePicker,
.dropdown {
    width: 100%;
    max-width: 225px;
    height: 40px;
}

.dropdownProfile{
    width: 230px;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .update-user-card {
        max-width: 100%;
    }

    .datePicker,
    .dropdown {
        max-width: 100%;
    }

    .pie-chart{
       width: 50%;
       height: 50%;
       margin-left: 25%;
       margin-right: 25%;
    }

    .dropdownProfile{
        width: 100%;
    }
}
