.transaction-history-container {
    display: flex;
    justify-content: center;
}

.transaction-history-card {
    width: 80%;
    max-width: fit-content;
    padding: 20px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.heading {
    margin-top: 10px;
    margin-bottom: 20px;
    color: #27ca9f;
}

td.incoming {
    color: green;
}

td.outgoing {
    color: red;
}

.spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

