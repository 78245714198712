.signup-page {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    font-family: 'Catamaran';
  }

  .spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
}
  
  .signup-card {
    padding: 2%;
    margin: 5%;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 40%;
    color: #fff;
  }
  
  .text-underline {
    text-decoration: underline;
    color: #27ca9f;
  }
  
  .password-toggle {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #27ca9f;
  }
  
  .input {
    width: 100%;
    padding: 12px 15px;
    margin: 15px 0;
    border: 1px solid #ccc;
    border-radius: 4px;    
  }

  .input:focus {
    border-color: #27ca9f; 
    outline: none;
  }
  
  .buttonSubmit {
    width: 100%;
    padding: 10px;
    background-color: #27ca9f;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    transition-duration: 0.4s;
  }

 
  
  .googleButton {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
  }
  
  .googleIcon {
    margin-right: 10px;
  }
  
  .heading {
    color: #27ca9f;
    font-size: 24px;
    text-align: left;
    margin-top: -11%;
    margin-bottom: 2%;
  }
  
  .logo {
    margin-top: -20px;
    width: 200px;
    height: 200px;
  }

  .signup-image {
    width: 50%;
    height: 100%;
    background-image: url('../images/registration.jpg');
    background-size: cover; 
    background-position: center; 
  }

  .signin-image{
    width: 50%;
    height: 100%;
    background-image: url('../images/login-3.jpg');
    background-size: cover;
    background-position: center;
  }

  .orText{
    font-size: 14px;
    color: #27ca9f;
    text-align: center;
  }
  
  
  /* Media query for mobile screens */
  @media (max-width: 768px) {
    .signup-page {
        display: flex;
        height: 100vh;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
    }
    
    .signup-card {
        border-radius: 10px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        width: 100%;
        color: #fff;
      }
  
      .signup-image,
      .signin-image {
          display: none;
      }
  }
  