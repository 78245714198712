body {
    margin: 0;
    padding: 0;
    height: 100%;
}

.footer {
    color: #000;
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 10px;
}


@media (max-width: 768px) {
    .footer {
        color: #000;
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        padding: 10px;
    }
}
