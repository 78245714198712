.beneficiaries-container {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    margin-left: 20%;
}

.beneficiary-card {
    width: 300px;
    margin: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out;
    box-sizing: border-box;
}

.beneficiary-card:hover {
    transform: scale(1.05);
}

.beneficiary-card .card-body {
    text-align: center;
    padding: 10px;
}

.beneficiary-card .btn-danger {
    margin-top: 10px;
    width: 100%;
    padding-top: 2%;
    padding-bottom: 2%;
}

.transactButton {
    margin-top: 10px;
    width: 100%;
}

.add-beneficiary-card {
    width: 300px;
    margin: 10px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out;
    box-sizing: border-box;
}

@media (max-width: 1200px) {
    .beneficiary-card {
        width: calc(50% - 20px);
    }
}

@media (max-width: 768px) {
    .beneficiary-card {
        width: calc(100% - 20px);
    }
}

@media (max-width: 576px) {
    .beneficiaries-container {
        flex-direction: column;
        align-items: stretch;
        margin-left: 0;
    }

    .beneficiary-card,
    .add-beneficiary-card {
        width: calc(100% - 20px);
    }
}

.modal-body {
    padding: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.modal-footer {
    justify-content: center;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 576px) {
    .modal-body {
        padding: 10px;
    }
}

@media (max-width: 400px) {
    .beneficiary-card,
    .add-beneficiary-card {
        width: calc(100% - 20px);
    }
}
