.navigationContainer {
    padding: 5px;
    height: 70px;
    position: fixed;
    width: 100%;
    background-color: #fff; 
    z-index: 1000;
}

.logo {
    margin-right: 10px;
}

.logo img {
    margin-top: 15%;
    margin-left: 5%;
    width: 80%;
    height: 80%;
    object-fit: contain;
}

.nav-link {
    display: flex;
    align-items: center;
    color: #333;
    text-decoration: none;
    padding: 5px;
}

.nav-link :hover {
    color: #27ca9f;
}

.icon {
    margin-right: 5px;
    position: relative;
}

.badge {
    background-color: #ff6347;
    color: #fff;
    border-radius: 50%;
    padding: 2px 6px;
    font-size: 12px;
    position: absolute;
    top: 50%;
    right: -8px;
    transform: translateY(-50%);
}
.mobile-menu-icon{
      display: none;  
}

@media (max-width: 768px) {
    .mobile-menu-icon{
        display: flex;  
        flex-direction: column;
  }
   
    .navigationContainer {
        height: 30%;
    }

    .logo img {
        margin-left: 10px;
        width: 100%;
        height: 100%;
    }

    .nav-link {
        padding-left: 10px;
        padding-right: 10px;
    }

    .icon {
        margin-right: 10px;
    }
}
