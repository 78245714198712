.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2%;
}

@media (max-width: 768px) {
    .container {
        flex-direction: column; 
    }
}

.container.hide-sidebar {
    @media (max-width: 768px) {
      margin-left: 0; 
    }
  }
