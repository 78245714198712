.settings-container {
  display: flex;
  justify-content: center;
  padding: 5%;
}

.custom-card {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

.settingsformInput {
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.settingsButtonInput {
  width: 40%;
  margin-top: 2%;
  margin-bottom: 2%;
  background-color: #27ca9f;
  color: #fff;
}

.error {
  color: red;
  text-align: center;
}

@media (max-width: 768px) {  
  .settingsButtonInput {
      width: 100%; 
  }

  .settingsformInput{
      width: 100%;
  }
}
