.googleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5; 
  }
  
  .googleCard {
    width: 450px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    background-color: white;
  }
  
  .logoGoogle {
    width: 50%; 
    height: 50%; 
    margin-bottom: 20px;
  }
  
  .backButton {
    margin-top: 20px;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    background-color: #27ca9f; 
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .backButton:hover {
    background-color: #27ca9f; 
  }
  
  .googleHeader{
    margin-top: 1%;
    margin-bottom: 4%;
    font-size: 25px;
    font-weight: 500;

  }